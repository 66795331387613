import Common from "@/plugin/common";
import {
    defineComponent,
    ref,
    reactive,
    onMounted
} from "@vue/runtime-core";
import store from "@/store";
import {
    useRouter
} from "vue-router";
export {
    Common,
    defineComponent,
    ref,
    reactive,
    onMounted,
    store,
    useRouter
}