import { createStore } from 'vuex'
//邢台商会无偿向市委统战部捐赠口罩 助力疫情防控
import list_img_YiQingJuanZhu1 from '@/assets/xinwen/yiqingjuanzeng/1.jpg'
import list_img_YiQingJuanZhu2 from '@/assets/xinwen/yiqingjuanzeng/2.jpg'

//商会结对帮扶 助力乡村振兴 秦皇岛市邢台商会开展村企结对帮扶活动 ----- 图片 
import list_img_BangFu1 from '@/assets/xinwen/bangfu/1.jpg'
import list_img_BangFu2 from '@/assets/xinwen/bangfu/2.jpg'
import list_img_BangFu3 from '@/assets/xinwen/bangfu/3.jpg'
import list_img_BangFu4 from '@/assets/xinwen/bangfu/4.jpg'

//“疫”路同行  志愿有我——记邢台商会志愿者风采
import list_img_FangYiZhiYuan1 from '@/assets/xinwen/fangyizhiyuan/1.jpg'
import list_img_FangYiZhiYuan2 from '@/assets/xinwen/fangyizhiyuan/2.jpg'
import list_img_FangYiZhiYuan3 from '@/assets/xinwen/fangyizhiyuan/3.jpg'
import list_img_FangYiZhiYuan4 from '@/assets/xinwen/fangyizhiyuan/4.jpg'

// 实地调研明方向  凝心聚力促发展
import sddy1 from '@/assets/xinwen/sddy/1.jpg'
export default createStore({
  state: {
    height: window.innerHeight, 
    url:"https://api.hebzhyl.com",
    press_data:[
      {
        list_img:sddy1,
        list_text:"实地调研明方向  凝心聚力促发展",
        list_date:"2023-4-19",
        list_firmName:"秦皇岛市邢台商会",
        list_detailsHTML:`
        <div style="
        border-bottom: 1px dashed #ccc;
        padding: 12px 0px;
        margin: 0 10px;
        text-align: left;
        font-size: 18px;
        letter-spacing: 1px;">
          实地调研明方向  凝心聚力促发展
        </div>
        <div style=" padding: 5px 0px;">
        ——邢台市南和区区长刘岩斌一行莅临秦皇岛市邢台商调研报道
        </div>
        
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        2023年4月19日上午，邢台市南和区区长刘岩斌率区行政审批局局长高自海、区驻深驻沪办主任宁洪波、区政府办副主任李少辉一行莅临秦皇岛市邢台商会，对商会商业活动做指导调研。
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        商会秘书长耿占杰、常务副会长胡伟、李建勋、陈士卫等人陪同介绍。
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        刘区长听取耿秘书长作的商会情况汇报后，对商会给予了高度评价，对邢台市的招商引资政策做了重点介绍，期待在外打拼的各界精英加强地方合作，常回家乡看看，引进来，走出去，为邢台、秦皇岛两地的商业发展做出贡献！
        </div>
        <img style="width: 100%;" src="${sddy1}" alt="">
        `
      },
      {
        list_img:list_img_FangYiZhiYuan1,
        list_text:"“疫”路同行  志愿有我——记邢台商会志愿者风采",
        list_date:"2022-10-13",
        list_firmName:"秦皇岛市邢台商会",
        list_detailsHTML:`
        <div style="
        border-bottom: 1px dashed #ccc;
        padding: 12px 0px;
        margin: 0 10px;
        text-align: left;
        font-size: 18px;
        letter-spacing: 1px;">
            “疫”路同行  志愿有我 ——记邢台商会志愿者风采
        </div>
        <div style=" padding: 5px 0px;">
            发布时间：2022.10.7
        </div>
        <img style="width: 100%;" src="${list_img_FangYiZhiYuan4}" alt="">
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        国庆期间，疫情袭击了平静的港城。在新冠病毒面前，在这个关键时刻，秦皇岛市邢台商会领导、党员、会员积极响应政府号召，参加抗疫第一线。勇当逆行志愿者！
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
            抗击疫情，不忘初心，牢记使命。在防疫一线前彰显本色。10月7日凌晨5:00邢台商会副会长、常务秘书长、共产党员郝杏强接到彩龙物业服务有限公司党支部孟书记突发疫情电话！第一时间站出来，加入党员先锋岗。向社区申请担任了志愿者。疫情就是命令，作为一名党员应该冲锋在前，勇当逆行志愿者，马上发动园区其他党员及入党积极分子及时到位封控园区，给即将上班的商户和客户及时做到了人身防疫安全工作！
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        10月13日晚6点至21点30分，邢台商会副会长、副秘书长、君德风律师所主任颜翔宇作为党员志愿者，在封控楼栋门口执勤，协助居民转运生活物资。
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        10月16日上午，邢台商会副会长、副秘书长、君德风律师事务所主任颜翔宇作为党员志愿者，到居住地北环路街道办事处和下辖迎秋西里社区，与街居主要领导共同分析一防控志愿者与辖区居民发生冲突事件，提供现场法律服务。 
        </div>

        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        10月13日、14日，邢台商会党员、会员脱泽龙在西港路街道先盛里社区，丽水家园小区参加疫情防控执勤，确保社区安全。 
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        务求实效，在攻坚克难上砥砺前行。在疫情防控阻击战中，他们是奋不顾身的“逆行者”、舍我其谁的他们勇士担责不误、临难不怯，坚定勇敢地站在抗击疫情最前线，在危难时刻挺身而出、英勇奋斗，无畏艰险、砥砺前行的身影，是先锋模范作用的生动体现。面对风险、面对危难，他们舍小家为大家、勇于担当，在落实决策部署的过程中迎接挑战、难题，做好各项防控工作，同时间赛跑，与病魔较量，用行动遏制疫情蔓延势头，势必打赢疫情防控阻击战。 
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        一名党员就是一面旗帜，一名企业家就是一个模范！发扬了邢商精神 ，发挥了邢商能量，铸就了邢商品牌，铸牢了抗疫红色防线。在这次疫情防控志愿者活动中，商会志愿者以良好的精神风貌和点滴的行动，展现了无私的大爱。为我市抗击疫情做出了贡献！大家齐心协力，众志成城。一定会早日取得最后的抗疫胜利！致敬最美逆行者 。 
        </div>
        <div style="
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        ">
            <img style="width: 49%;" src="${list_img_FangYiZhiYuan2}" alt="">
            <img style="width: 49%;" src="${list_img_FangYiZhiYuan3}" alt="">
        </div>
        `
      },
      {
        list_img:list_img_BangFu3,
        list_text:"商会结对帮扶 助力乡村振兴 秦皇岛市邢台商会开展村企结对帮扶活动",
        list_date:"2022-9-27",
        list_firmName:"秦皇岛市邢台商会",
        list_detailsHTML:`
        <div style="
        border-bottom: 1px dashed #ccc;
        padding: 12px 0px;
        margin: 0 10px;
        text-align: left;
        font-size: 18px;
        letter-spacing: 1px;">
            商会结对帮扶 助力乡村振兴 秦皇岛市邢台商会开展村企结对帮扶活动
        </div>
        <div style=" padding: 5px 0px;">
            发布时间：2022.9.27
        </div>
        <img style="width: 100%;" src="${list_img_BangFu4}" alt="">
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
            为深入贯彻落实乡村振兴战略，扎实开展“万企兴万村”行动。秦皇岛市邢台商会立即付诸行动。9月27日上午，秦皇岛市邢台商会在市工商联经联处处长高宝奇陪同下，到青龙县安子岭乡白蟒山村开展“万企兴万村”调研考察活动。邢台商会会长杨建忠，常务会长陈士伟，常务会长李建勋，副会长郝杏强、贾泽瑞、荆建豪、杨志峰等以及办公室人员9人出席了活动。青龙发改局包村第一书记杨国玥,白蟒山村书记张福喜，白蟒山村文书王力军热情接待了商会一行。
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
            青龙发改局包村第一书记杨国玥对青龙安子岭乡白蟒山村情况做了详细介绍。白蟒山村位于青龙县东部，隶属安子岭乡管辖，距青龙县城50公里，东邻秦皇岛市区63公里，全村总面积6.73平方公里，山场面积10000亩，耕地面积740亩，人均耕地面积0.82亩。2011年被确定为扶贫开发重点村（十二五贫困村），2018年全村实现脱贫。村两委4人，党员30人，村民代表19人。全村共辖5个村民小组，292户，867人。脱贫户5户9人，五保13户13人，低保34户41人。村集体经济薄弱，村民收入以传统种植（苹果、板栗）养殖业（猪、羊）及外出务工为主。2021年村集体经济收入4.6万元，主要来源是天保林和公益林款。
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        座谈会上，杨建忠会长首先传达了秦皇岛市“万企兴万村”行动启动大会精神，重点传达了市工商联领导对开展“万企兴万村”活动的指示精神。就如何更好开展“万企兴万村”行动助推乡村全面振兴，为巩固脱贫攻坚成果与乡村振兴有效衔接贡献民营经济力量进行了交流发言。此次调研根据该村具体情况，商会领导提出制定了发展林果业，蔬菜种植产业，特色农产品产销；农村小型养殖鸡、猪等；建设发展民宿，高科技引进农作物栗子树菌类研究等脱贫攻坚工作规划。青龙发改局包村第一书记杨国玥,白蟒山村书记张福喜表示，这次有商会调研组给支招，白蟒山村的发展工作大有希望谋得出路。他们十分感谢秦皇岛市邢台商会给予大力支持，将“万企兴万村”行动落到实处，努力实现乡村振兴与企业发展互促共赢。
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        座谈会后，秦皇岛市邢台商会代表全体会员向白蟒山村捐赠了一些爱心物资，给村里的贫困户准备了米、面、油等生活用品，体现了商会集体温暖的关怀。 
        </div>
        <div style="
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        ">
            <img style="width: 49%;" src="${list_img_BangFu1}" alt="">
            <img style="width: 49%;" src="${list_img_BangFu2}" alt="">
        </div>
        `
      },
      {
        list_img:list_img_YiQingJuanZhu1,
        list_text:"邢台商会无偿向市委统战部捐赠口罩 助力疫情防控",
        list_date:"2022-9-23",
        list_firmName:"秦皇岛市邢台商会",
        list_detailsHTML:`
        <div style="
        border-bottom: 1px dashed #ccc;
        padding: 12px 0px;
        margin: 0 10px;
        text-align: left;
        font-size: 18px;
        letter-spacing: 1px;">
            邢台商会无偿向市委统战部捐赠口罩 助力疫情防控
        </div>
        <div style=" padding: 5px 0px;">
            发布时间：2022.9.23
        </div>
        <img style="width: 100%;" src="${list_img_YiQingJuanZhu1}" alt="">
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
            据了解，针对全国疫情防控出现的新形势及我市应对新冠疫情防控工作，常态化核酸检测的新情况，市工商联宣传处处长白志龙积极协调商会资源。2022年9月22日、23日，秦皇岛市邢台商会会长杨建忠和办公室人员一行代表邢台商会。向秦皇岛市委统战部捐赠医用防护口罩2箱(4800只)。防护面罩2箱(400只)。
        </div>
        <div style="
        text-indent: 30px;
        letter-spacing: 1px;
        text-align: left;
        color: #6E6E6E;
        ">
        市委统战部二级调研员张建民，市工商联领导对秦皇岛市邢台商会的此次捐赠表示衷心感谢。此次捐赠的2箱(4800只)防护口罩，2箱(400只)防护面罩将全部用于统战系统防控一线。邢台商会用实际行动助力一线疫情防控工作。为我市疫情防控做出了贡献。
        </div>

        <div style="
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        ">
            <img style="width: 100%;" src="${list_img_YiQingJuanZhu2}" alt="">
        </div>
        `
      },
    ]
  },
  setHeight(value) {
    this.state.height = value;
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
