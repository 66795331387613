import { createRouter, createWebHashHistory } from 'vue-router'

const routerHistory = createWebHashHistory();
const router = createRouter({
  history: routerHistory,
  routes:[
    {
      path: '/',
      name: 'index',
      component:()=> import('../views/index/index.vue'),
      meta: {
        active: 1,
        title: "首页"
      }
    },
    {
      path: '/dynamically',
      name: 'dynamically',
      component:()=> import('../views/dynamically/index.vue'),
      meta: {
        active: 2,
        title: "动态"
      }
    },
    {
      path: '/initiationApply',
      name: 'initiationApply',
      component:()=> import('../views/initiationApply/index.vue'),
      meta: {
        active: 1,
        title: "入会申请"
      }
    },
    {
      path: '/introductory',
      name: 'introductory',
      component:()=> import('../views/introductory/index.vue'),
      meta: {
        active: 1,
        title: "商会介绍"
      }
    },
    {
      path: '/addressBook',
      name: 'addressBook',
      component:()=> import('../views/addressBook/index.vue'),
      meta: {
        active: 1,
        title: "商会通讯录"
      }
    },
    {
      path: '/addressBookDetails',
      name: 'addressBookDetails',
      component:()=> import('../views/addressBook/addressBookDetails/index.vue'),
      meta: {
        active: 1,
        title: "通讯录详情"
      }
    },
    {
      path: '/publicCharity',
      name: 'publicCharity',
      component:()=> import('../views/publicCharity/index.vue'),
      meta: {
        active: 3,
        title: "慈善公益"
      }
    },
    {
      path: '/constitution',
      name: 'constitution',
      component:()=> import('../views/constitution/index.vue'),
      meta: {
        active: 1,
        title: "商会章程"
      }
    },
    {
      path: '/XingtaiShow',
      name: 'XingtaiShow',
      component:()=> import('../views/XingtaiShow/index.vue'),
      meta: {
        active: 1,
        title: "邢商风采"
      }
    },
    {
      path: '/dynamicallyDetails',
      name: 'dynamicallyDetails',
      component:()=> import('../views/dynamicallyDetails/index.vue'),
      meta: {
        active: 2,
        title: "邢商风采"
      }
    },
    {
      path: '/my',
      name: 'my',
      component:()=> import('../views/my/index.vue'),
      meta: {
        active: 4,
        title: "个人中心"
      }
    },
  ]
})

export default router
