import {
    ElMessage
} from 'element-plus';
// import { Toast } from 'vant';
export default {
    // ElMessage: ElMessage,
    throttle(fn) {
        let timer = null;
        return function () {
            if (!timer) {
                timer = setTimeout(function () {
                    fn();
                    clearTimeout(timer);
                    timer = null;
                }, 500)
            }
        }
    },
    delDom() {
        Array.from(document.querySelectorAll("div")).forEach(e => {
            if (e.className.indexOf("el-message--info") != -1) {
                document.body.removeChild(e);
            }
        })
    },
    Clear() {
        ElMessage.closeAll()
    },
    Load(msg, duration, fn) {
        this.Clear();
        ElMessage({
            message: msg,
            duration: duration || 3000,
            onClose: (e) => {
                fn && fn()
            }
        })
    },
    Fail(msg, duration, fn) {
        let that = this;
        this.Clear();
        ElMessage({
            message: msg,
            type: "error",
            offset:40,
            duration: duration || 3000,
            onClose: (e) => {
                fn && fn()
                that.delDom();
            }
        })
    },
    Suc(msg, duration, fn) {
        let that = this;
        this.Clear();
        ElMessage({
            message: msg,
            type: "success",
            duration: duration || 3000,
            onClose: (e) => {
                fn && fn();
                that.delDom();
            }
        })
    },
    Warning(msg, duration, fn) {
        this.Clear();
        ElMessage({
            message: msg,
            type: "warning",
            duration: duration || 2000,
            onClose: (e) => {
                fn && fn();
            }
        })
    },
    Empty(value, name) {
        if (value == "" || typeof value == 'undefined') {
            return name + "不能为空";
        }
    },
    ToastAll(msgArray) {
        if (msgArray.length != 0) {
            this.Fail(msgArray.shift());
            return false;
        }
        return true;
    },
    mobileSystem() {
        var u = navigator.userAgent;
        var isXiaomi = u.indexOf('XiaoMi') > -1; // 小米手机
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; // 其它安卓
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios
        return new Promise((resolve, reject) => {
            let sys = ""
            if (isAndroid) {
                sys = 'Android';
            } else if (isIOS) {
                if (isXiaomi) {
                    sys = 'Android';
                } else {
                    sys = 'IOS';
                }
            }
            if (sys) {
                resolve(sys);
            }
        })
    },
    //通过身份证号返回（性别、年龄）
    idCardAnalysis(id) {
        console.log(id)
        let sexAndAge = {};
        let userCard = id;
        if(parseInt(userCard.substr(16,1)) % 2 == 1){
            sexAndAge.sex = '男'
        }else{
            sexAndAge.sex = '女'
        }
        let yearBirth = userCard.substring(6,10);
        let monthBirth = userCard.substring(10,12);
        let dayBirth = userCard.substring(12,14);
        //获取当前年月日并计算年龄
        let myDate = new Date();
        let monthNow = myDate.getMonth() + 1;
        let dayNow = myDate.getDay();
        let age = myDate.getFullYear() - yearBirth;
        if(monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)){
            age--;
        }
        //得到年龄
        sexAndAge.age = age;
        //返回性别和年龄
	    return sexAndAge;
    },
    dateConversion(date){
        var seperator1 = "-";
        var seperator2 = ":";
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        var Hours = date.getHours();
        var Minutes = date.getMinutes();
        var Seconds = date.getSeconds();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        if (Hours >= 0 && Hours <= 9) {
            Hours = "0" + Hours;
        }
        if (Minutes >= 0 && Minutes <= 9) {
            Minutes = "0" + Minutes;
        }
        if (Seconds >= 0 && Seconds <= 9) {
            Seconds = "0" + Seconds;
        }
        var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
        return currentdate;
    },
    //封装授权
    loaded(){
        let paramIndex = location.href.indexOf("token");
        let openid = location.href.indexOf("openid");
        //如果没有token 去授权
        console.log(localStorage.getItem("yltToken"))
        console.log(paramIndex)
        if (!localStorage.getItem("yltToken") && paramIndex === -1 && openid === -1) {
            // this.setOauth();
        }
        //如果有token且url也有token，则替换token
        if (paramIndex !== -1) {
            let tokenBefore = location.href.slice(paramIndex);
            let token = tokenBefore.slice(tokenBefore.indexOf("=") + 1);
            let searchJ = token.indexOf("&");
            if (searchJ !== -1) {
                token = token.slice(0, searchJ);
            }
            let searchQ = token.indexOf("#");
            if (searchQ !== -1) {
                token = token.slice(0, searchQ);
            }
            localStorage.setItem("yltToken", token);
            //请求接口为验证
            // console.log("重新登录",token)
            // getHealthCard().then((res)=>{
            //    console.log(res)
            // }).catch(()=>{ 
            // })

        }
    },
    setOauth() {
        // location.href = 'https://' + location.host + '/api/qhdgryy/user/toGrantAuth?url=' + 'https://' + location.host + '/index/';
        // location.href = 'https://' + location.host + '/api/qhdgryy/user/toGrantAuth?url=' + 'https://' + location.host + '/indexx/dist/index.html';
        location.href = "https://test.hebzhyl.com/api/mobile/toGrantAuth?url=" + 'http://' + location.host + '/';
        
    }, 
}