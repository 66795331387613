"use strict";

import axios from "axios";
import Url from './url.js';
let config = {
  baseURL: Url,
  // withCredentials: true // 允许携带cookie
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) { 
    // config['headers']['Token'] = localStorage.getItem("hsMobileTokenP");
    config['headers']['Token'] = "2c26bdd6-12e5-4b76-b646-4af82a75e5a1"
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;