/**
 * Form detection class;
 * you must be deliver three Arrays to the child of the class if you want to use.
 */
 let Check = {
    isEmpty(value, msg) {
        if (Array.isArray(value)) {
            if (value.length == 0) {
                return msg;
            }
        }
        if (!value) {
            return msg;
        }
    },
    idCard: function (value, msg) {
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!reg.test(value)) {
            return msg;
        }
    },
    mobile: function (value, msg) {
        let phoneReg = /^1[3456789]\d{9}$/;
        if (!phoneReg.test(value)) {
            return msg;
        }
    },
    haveHz: function (value, msg) {
        let hzReg = /[\u4E00-\u9FA5]/g;
        if (hzReg.test(value)) {
            return msg;
        }
    }
};
class Form {
    list = [];
    add = (value, rule, errorMsg) => {
        value.forEach((e, i) => {
            this.list.push(() => {
                return Check[rule[i]].call(this, e, errorMsg[i]);
            });
        })
    }
    start = () => {
        for (let i = 0; i < this.list.length; i++) {
            let msg = this.list[i]();
            if (msg) {
                return msg;
            }
        }
    }
}
export default Form;