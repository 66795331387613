<template>
    <div class="mobile_head">
        <div class="mobile_head_left" @click="back">
            <!-- <el-icon v-if="false" :size="20"><CloseBold /></el-icon> -->
            <van-icon  :size="20" name="arrow-left" />
        </div>
        <div class="mobile_head_centre">{{init}}</div>
        <!-- <div class="mobile_head_right">其他</div> -->
    </div>
</template>

<script>
import {ref,reactive,
  defineComponent 
} from "@/plugin/importCommonUse";
import { computed } from 'vue'
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
    setup(props, context) {
        let router = useRoute();
        let route = useRouter();
        let init = computed(()=>{ 
            return router.meta.title
        })
        let back = ()=>{
            route.go(-1);
        }
        return {
            init,back
        };
    },
})
</script>
<style>
.mobile_head{
    position: relative;
    box-shadow: var(--el-box-shadow-light);
    background: #F8F9F9;
    /* border-bottom: 1px solid #dcdee2; */
}
.mobile_head_left{
    position: absolute;
    left: 10px;
    transform: translate(0%,45%);
}
.mobile_head_centre{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.mobile_head_right{
    position: absolute;
    right: 10px;
    transform: translate(0%,50%);
}
</style>
