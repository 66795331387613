<template>
    <div class="mobile_bottom">
        <div class="mobile_bottom_son" @click="skip('/')">
            <img v-if="init !==1" style="margin-bottom: -5px;" width="30" src="@/assets/index/shouye.png" alt="">
            <img v-if="init ==1" style="margin-bottom: -5px;" width="30" src="@/assets/index/shouye.png" alt="">
            <div style="font-size: 15px;" :style="{color:init ==1?'#E13C3A':'#939393'}">首页</div>
        </div>
        <div class="mobile_bottom_son" @click="skip('/dynamically')">
            <img v-if="init !==2" style="margin-bottom: -5px;" width="30" src="@/assets/index/dongtai.png" alt="">
            <img v-if="init ==2" style="margin-bottom: -5px;" width="30" src="@/assets/index/dongtai.png" alt="">
            <div style="font-size: 15px;" :style="{color:init ==2?'#E13C3A':'#939393'}">动态</div>
        </div>
        <div class="mobile_bottom_son" @click="skip('/publicCharity')">
            <img v-if="init !==3" style="margin-bottom: -5px;" width="30" src="@/assets/index/huodong.png" alt="">
            <img v-if="init ==3" style="margin-bottom: -5px;" width="30" src="@/assets/index/huodong.png" alt="">
            <div style="font-size: 15px;" :style="{color:init ==3?'#E13C3A':'#939393'}">活动</div>
        </div>
        <div class="mobile_bottom_son" @click="skip('/my')">
            <img style="margin-bottom: -5px;" width="30" src="@/assets/index/wode.png" alt="">
            <div style="font-size: 15px;" :style="{color:init ==4?'#E13C3A':'#939393'}">我的</div>
        </div>
    </div>
</template>

<script>
import {
  defineComponent
} from "@/plugin/importCommonUse";
import { computed } from 'vue'
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
    setup() {
        let route = useRoute();
        let router = useRouter();
        let init = computed(()=>{ 
            console.log(route.meta.active)
            return route.meta.active;
        })
        let skip = (url)=>{
            router.push(url);
        }
        return {
            init,skip
        };
    },
})
</script>
<style>
.mobile_bottom{
    position: relative;
    box-shadow: var(--el-box-shadow-light);
    background: #E9E9E9;
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid #dcdee2; */
}
.mobile_bottom_son{
    width: 50%;
}


</style>
