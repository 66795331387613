<template>
<!-- position:absolute;top:0px;left:0px; -->
  <mobileHead v-if="isHead" style="height:6.5vh; width: 100%;z-index: 99999;"/>
  <router-view style="overflow:auto; overflow-y: scroll;" :style="{height:isHead ? '83.5vh':'90vh'}"/>
  <mobileBottom style="position: fixed;
  top: 0px;
  overflow-y: scroll;height:10vh; width: 100%;z-index: 99999;" :style="{top:setHeight-70+'px'}"/>
</template>
<script>
import {
  defineComponent,store,ref , Common
} from "@/plugin/importCommonUse";
import {computed} from 'vue'
import mobileHead from '@/components/mobileHead/index.vue'
import mobileBottom from '@/components/mobileBottom/index.vue'
import { useRoute, useRouter } from "vue-router";
import {getUserInfo} from "@/api/data"
export default defineComponent({
  components: {
    mobileHead,mobileBottom
  },
  setup(){
    let init = ()=>{
      Common.loaded(); 
      getUserInfo().then((res)=>{
          if (res.data.code == 0) {
              Common.Fail(res.data.msg);
              return;
          }
          localStorage.setItem("userInfo",JSON.stringify(res.data.data))
      })
    }
    let router = useRoute();
    let minusHeight= ref()
    let setHeight = computed(()=>{
      console.log(store.state.height)
      return store.state.height;
    })
    let isHead = computed(()=>{ 
      if(router.meta.title == "首页" || router.meta.title == "登录"){
        if(router.meta.title == "首页"){
          minusHeight.value = 60
        }else{
          minusHeight.value = 0
        }
        return false;
      }else{
        minusHeight.value = 100
        return true;
      }
      
    })
    let isBottom = computed(()=>{
      if(router.meta.title == "登录"){
        return false;
      }else{
        return true;
      }
    })
    // init();
    return { 
      setHeight,isHead,minusHeight,isBottom
    }
  }
})
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
