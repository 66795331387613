import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./router/beforeEach";
//全局注册表单验证方法
import Form from '@/plugin/formCheck.js';
const app = createApp(App);
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.config.globalProperties.$Form = Form;

app.use(store).use(Vant).use(ElementPlus).use(router).mount('#app')
 