import router from './index';
router.beforeEach((to, from, next) => {
    // const token = localStorage.getItem("hsMobileTokenP"); 
    // if (!token && to['path'] != '/login') {
    //     next({
    //         name: 'login'
    //     });
    // } else {
        console.log(to)
        next();
    // }
})