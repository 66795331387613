import axios from '@/requestUtil/proxy.js';
import URL from '@/requestUtil/url';

//登录接口
export let login = (account,password,openid)=>{
    return axios.post(URL+"/api/mobile/login",{
        account,password,openid
    })
}

//提交
export let toRegister = (data)=>{
    return axios.post(URL+"/api/xszj/user/toRegister",data)
}

