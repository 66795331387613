import _axios from './axios';
import common from '@/plugin/common'; 
let testConsole = (fn, ...args) => {
   if (process.env.NODE_ENV !== 'production') {
      // console.log(process.env.NODE_ENV)
      return fn.apply(null, args);
   }
}
let RequestHandler = (args, data) => {
   common.Clear();
   // common.Load("正在加载,请稍后。", 99999);
   testConsole(console.time, "请求时间");
   testConsole(console.log, `%c○请求地址💥：  ${args[0]}  \n○参数为💥： ${args[1] && JSON.stringify(args[1])} `, " border:1px solid;padding:5px;margin:1px;color:#19be6b;font-size:1em")
   data.then((res) => {
      common.Clear();
      testConsole(console.log, `%c 请求结果为🌏：` + JSON.stringify(res), "color:#2d8cf0;border:1px solid;padding:5px;margin:1px;");
      testConsole(console.timeEnd, "请求时间");
      if (res.data.code === 0 && res.data.msg === "请授权") {
         common.Fail(res.data.msg, 1000, () => {
            let userInfo = JSON.parse(localStorage.getItem("userinfo") || "{}");
            if (userInfo['user_id']) {
               common.setOauth(userInfo['user_id'])
            }
         })
      }  
   }).catch((err) => {
      if (JSON.stringify(err).indexOf(401) !== -1) {
         common.Clear(); 
         common.Fail("登录过期，请重新登陆", 2000, () => { 
            localStorage.clear();
         });
         return;
      }
      common.Clear();
      common.Fail("服务器错误请联系管理员。", 3000);
      testConsole(console.timeEnd, "请求时间");
      testConsole(console.Fail, "请求失败" + JSON.stringify(err));
   })
}
_axios.post = new Proxy(_axios.post, {
   apply(target, thisArg, args) {
      const res = target.apply(thisArg, args);
      RequestHandler(args, res);
      return res;
   }
})
_axios.get = new Proxy(_axios.get, {
   apply(target, thisArg, args) {
      const res = target.apply(thisArg, args);
      RequestHandler(args, res);
      return res;
   }
})
export default _axios;